import { Interpolation, Theme } from "@emotion/react"
import { Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import Accordion from "../Accordion/Accordion"
import useButtonStates from "../../hooks/useButtonStates"
import { AmenitiesListProps, InternalTextLinkProps } from "./Amenities.d"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"

const InternalTextLink: React.FC<InternalTextLinkProps> = props => {
  const { title, target, to, disabled, css } = props
  const { handleFocus, handleBlur, focus } = useButtonStates()

  return (
    <Link
      className="group"
      onFocus={handleFocus}
      onBlur={handleBlur}
      css={[
        tw`outline-none p-2 -ml-2 self-start hover:underline`,
        focus && tw`underline border border-dotted border-blue-250 mt-[-1px]`,
        css,
        disabled && tw`pointer-events-none`,
      ]}
      target={target}
      title={title}
      to={to}
    >
      <span
        css={[
          tw`text-blue-250 group-hover:text-blue-300 group-active:underline`,
          focus && tw`ml-[-1px]`,
          disabled && tw`opacity-40`,
        ]}
      >
        {title}
      </span>
    </Link>
  )
}

const AmenitiesList: React.FC<AmenitiesListProps> = ({
  title,
  description,
  locationMapIdentifier,
  index,
}) => {
  const linkTitle = "View on Map"
  const [text, setText] = useState({ title, description, linkTitle })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, description, linkTitle })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, description, linkTitle])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <Accordion index={index} title={text?.title} key={index}>
      <p
        css={[tw`max-w-3xl mb-4`]}
        dangerouslySetInnerHTML={{ __html: text?.description }}
      />
      {locationMapIdentifier?.length < 4 && (
        <div css={[tw`mb-4`]}>
          <InternalTextLink
            title={text?.linkTitle}
            css={[tw`text-[#385885]`]}
            to={`/terminal-maps?location=${locationMapIdentifier}`}
          />
        </div>
      )}
    </Accordion>
  )
}

export default AmenitiesList
