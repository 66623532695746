import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import { AmenitiesWifiProps, WifiStepCardProps } from "./Amenities.d"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { Image } from "../Image"

const WifiStepCard: React.FC<WifiStepCardProps> = ({
  title,
  description,
  backgroundImage,
  icon,
}) => {
  const [text, setText] = useState({ title, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`max-w-[25rem] flex flex-col mx-auto gap-5 mt-5 md:mt-0`]}>
      <div
        css={[
          tw`relative bg-blue-300 text-white flex flex-col justify-center items-center gap-5`,
        ]}
      >
        <Image
          role="presentation"
          alt=""
          css={[tw`h-[13rem] grayscale opacity-25 mix-blend-luminosity`]}
          src={getImage(
            backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
          )}
          publicURL={backgroundImage?.localFile?.publicURL}
        />
        <div
          css={[
            tw`absolute flex flex-col gap-5 justify-center items-center w-full h-full text-center p-4`,
          ]}
        >
          <Image aria-hidden css={[tw``]} src={icon.localFile.publicURL} alt="" />
          <span css={[tw`text-2xl`]}>{text?.title}</span>
        </div>
      </div>
      <p
        css={[tw`px-4 text-center`]}
        dangerouslySetInnerHTML={{ __html: text?.description }}
      />
    </div>
  )
}

const AmenitiesWifi: React.FC<AmenitiesWifiProps> = ({
  accessWifiSteps,
  description,
  headline,
}) => {
  const [text, setText] = useState({ headline, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`mx-4 md:mx-auto mt-16 md:px-4`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <p
        css={[tw`mt-6 max-w-xl text-center mx-auto`]}
        dangerouslySetInnerHTML={{ __html: text?.description }}
      />
      <div
        css={[tw`flex flex-col md:flex-row gap-5 justify-center mt-8 mx-auto`]}
      >
        {accessWifiSteps?.map((item, index) => (
          <WifiStepCard key={index} {...item} />
        ))}
      </div>
    </article>
  )
}

export default AmenitiesWifi
