import { graphql } from "gatsby"
import React from "react"
import AmenitiesWifi from "../../components/Amenities/AmenitiesWifi"
import { TemplateProps } from "../templates"
import AmenitiesTitle from "../../components/Amenities/AmenitiesTitle"
import AmenitiesList from "../../components/Amenities/AmenitiesList"
import tw from "twin.macro"
import { AmenitiesContentTemplatePageData } from "../amenities"
import ImageCarousel from "../../components/Carousel/ImageCarousel/ImageCarousel"
import { SEO } from "../../components/SEO"
import { BackgroundContainer } from "../../components/BackgroundContainer"

const amenities: React.FC = ({
  data,
  location,
}: TemplateProps<AmenitiesContentTemplatePageData>) => {
  const { title, content, template } = data.wpPage
  const { accessWifiSection, amenities, amenitiesHeroCarouselSlides } =
    template.amenitiesPage

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <ImageCarousel css={[tw`h-80`]} slides={amenitiesHeroCarouselSlides} />
      <BackgroundContainer url={"url('/airport-interior.png')"}>
        <AmenitiesTitle title={title} content={content} />
        <AmenitiesWifi {...accessWifiSection} />
      </BackgroundContainer>
      <article css={[tw`max-w-2xl mx-8 sm:p-4 md:mx-auto mt-20 lg:max-w-4xl`]}>
        {amenities?.map(
          ({ title, description, locationMapIdentifier }, i) => (
            <AmenitiesList
              title={title}
              description={description}
              locationMapIdentifier={locationMapIdentifier}
              index={i}
              key={i}
            />
          )
        )}
      </article>
    </div>
  )
}

export default amenities

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_Amenities {
          amenitiesPage {
            accessWifiSection {
              accessWifiSteps {
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                icon {
                  localFile {
                    publicURL
                  }
                }
                description
                title
              }
              description
              headline
            }
            amenities {
              description
              locationMapIdentifier
              title
            }
            amenitiesHeroCarouselSlides {
              backgroundImage {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                publicUrl
                altText
              }
              description
              headline
            }
          }
        }
      }
    }
  }
`
